import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Globle } from 'src/app/Shared/global';
import { monthlyreportFilterModel } from 'src/Models/monthlyreportFilterModel';
import { SnapshotandSummaryReportModel } from 'src/Models/SnapshotandSummaryReportModel';
import { UsersService } from 'src/Services/users.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import * as FileSaver from 'file-saver';
import { UniversalService } from 'src/Services/universal.service';
import { Inject } from '@angular/core';

@Component({
  selector: 'app-snapshot-summary-details-report',
  templateUrl: './snapshot-summary-details-report.component.html',
  styleUrls: ['./snapshot-summary-details-report.component.css']
})
export class SnapshotandSummaryDetailsReportComponent implements OnInit {
  filterModel = new monthlyreportFilterModel();
  exportFilterModel = new monthlyreportFilterModel();
  listOfSnapshotandSummaryReport: SnapshotandSummaryReportModel[];
  listOfExports: SnapshotandSummaryReportModel[];
  totalRecordsCount: number = 0;
  isAscending: boolean;
  isloading = false;
  recordsCount: number = -1;
  currentPage: number = 1;
  monthandyear: string = '';
  currentmonth: boolean = false;
  upDownsnapshotPeriodCSS: string = '';
  upDownstudyAreaCodeCSS: string = '';
  upDownenrollmentDateCSS: string = '';
  upDownenrollmentCodeCSS: string = '';
  upDowneTCGeneralCSS: string = '';
  upDownlastNameCSS: string = '';
  upDownfirstNameCSS: string = '';
  upDownmiddleNameCSS: string = '';
  upDownAPIApplicationIdCSS: string = '';
  upDownsubscriberIdCSS: string = '';
  upDowntelephoneNumberCSS: string = '';
  upDownserviceTypeCSS: string = '';
  upDownmonthandyearCSS: string = '';
  upDownstreetAddressCSS: string = '';
  upDowncityCSS: string = '';
  upDownstateCSS: string = '';
  upDownzIPCSS: string = '';
  upDownmailingStreetAddressCSS: string = '';
  upDownmailingCityCSS: string = '';
  upDownmailingStateCSS: string = '';
  upDownmailingZIPCSS: string = '';
  upDownserviceInitiationDateCSS: string = '';
  upDowneligibilityProgramCSS: string = '';
  upDownbQPLastNameCSS: string = '';
  upDownbQPFirstNameCSS: string = '';
  upDownbQPMiddleNameCSS: string = '';
  upDowntribalBenefitFlagCSS: string = '';
  upDowndeviceReimbursementDateCSS: string = '';
  upDowndeviceTypeCSS: string = '';
  upDowndeviceMakeCSS: string = '';
  upDownexpectedDeviceReimbursementRateCSS: string = '';
  upDowndeviceCopayCSS: string = '';
  upDowndeviceDeliveryMethodCSS: string = '';
  upDowndeviceModelCSS: string = '';
  upDownmodelNumberCSS: string = '';
  upDownmarketValueCSS: string = '';
  upDownconsumerFeeCSS: string = '';
  upDowncontactPhoneNumberCSS: string = '';
  upDownaVPProgramExceptionCSS: string = '';
  upDownschoolLunchExceptionCSS: string = '';
  upDownaMSFailureExceptionCSS: string = '';
  upDownduplicateAddressExceptionCSS: string = '';
  upDownexpectedReimbursementRateCSS: string = '';
  upDowneligibleforTransferDateCSS: string = '';
  upDowneligibleforDeviceCSS: string = '';
  upDowneligibleforRateCSS: string = '';
  upDowncreatedDateCSS: string = '';

  constructor(private _fb: FormBuilder, private router: Router, private userService: UsersService, private titleService: Title,
    private globle: Globle, private toastr: ToastrService) {

    this.filterModel.pageNumber = 1;
    this.filterModel.pageSize = 10;
    this.filterModel.orderBy = 'snapshotPeriod desc';
    this.exportFilterModel.monthandyear = sessionStorage.getItem('snapshotPeriod');
    this.filterModel.monthandyear = sessionStorage.getItem('snapshotPeriod');
    this.monthandyear = sessionStorage.getItem('monthandyear');
    
    let currentMonth = sessionStorage.getItem('currentmonth');

    if(currentMonth=="1"){
      this.currentmonth=true;
    }
    else{
      this.currentmonth=false;
    }

    this.titleService.setTitle('Summary and Detail Subscriber Snapshot Report');
  }

  ngOnInit() {
    this.GetMonthlySnapshotandSummaryByPaged();
  }

  GetMonthlySnapshotandSummaryByPaged() {
    this.isloading = true;
    this.listOfSnapshotandSummaryReport = [];

    this.userService.GetSnapshotandSummaryByPaged(this.filterModel).subscribe(
      data => {
        this.exportFilterModel = Object.assign({}, this.filterModel);
        this.exportFilterModel.pageSize = 100000;
        this.exportFilterModel.pageNumber = 1;

        this.isloading = false;
        this.listOfSnapshotandSummaryReport = data;
        if (this.listOfSnapshotandSummaryReport != null) {
          if (this.listOfSnapshotandSummaryReport.length > 0) {
            this.totalRecordsCount = this.listOfSnapshotandSummaryReport[0].totalRecordCount;
            this.recordsCount = this.totalRecordsCount;
          }
          else {
            this.totalRecordsCount = 0;
            this.recordsCount = 0;
          }
        }
        else {
          this.totalRecordsCount = 0;
          this.recordsCount = 0;
        }
      },
      error => {
        this.isloading = false;
        this.recordsCount = 0;
        console.log(error);
      }
    );
  }

  sort(sortBy: any) {
    this.cleanCssClass();
    this.isAscending = !this.isAscending;
    this.filterModel.orderBy = this.isAscending ? sortBy : sortBy + ' DESC';

    switch (sortBy) {
      case 'snapshotPeriod': this.upDownsnapshotPeriodCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'studyAreaCode': this.upDownstudyAreaCodeCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'enrollmentDate': this.upDownenrollmentDateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'enrollmentCode': this.upDownenrollmentCodeCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'eTCGeneral': this.upDowneTCGeneralCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'lastName': this.upDownlastNameCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'firstName': this.upDownfirstNameCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'middleName': this.upDownmiddleNameCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'APIApplicationId': this.upDownAPIApplicationIdCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'subscriberId': this.upDownsubscriberIdCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'telephoneNumber': this.upDowntelephoneNumberCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'serviceType': this.upDownserviceTypeCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'streetAddress': this.upDownstreetAddressCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'city': this.upDowncityCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'state': this.upDownstateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'zIP': this.upDownzIPCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'mailingStreetAddress': this.upDownmailingStreetAddressCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'mailingCity': this.upDownmailingCityCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'mailingState': this.upDownmailingStateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'mailingZIP': this.upDownmailingZIPCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'serviceInitiationDate': this.upDownserviceInitiationDateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'eligibilityProgram': this.upDowneligibilityProgramCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'bQPLastName': this.upDownbQPLastNameCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'upDownbQPMiddleNameCSS': this.upDownbQPMiddleNameCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'tribalBenefitFlag': this.upDowntribalBenefitFlagCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'deviceReimbursementDate': this.upDowndeviceReimbursementDateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'deviceType': this.upDowndeviceTypeCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'deviceMake': this.upDowndeviceMakeCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'expectedDeviceReimbursementRate': this.upDownexpectedDeviceReimbursementRateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'deviceCopay': this.upDowndeviceCopayCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'deviceDeliveryMethod': this.upDowndeviceDeliveryMethodCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'deviceModel': this.upDowndeviceModelCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'modelNumber': this.upDownmodelNumberCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'marketValue': this.upDownmarketValueCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'consumerFee': this.upDownconsumerFeeCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'contactPhoneNumber': this.upDowncontactPhoneNumberCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'aVPProgramException': this.upDownaVPProgramExceptionCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'schoolLunchException': this.upDownschoolLunchExceptionCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'aMSFailureException': this.upDownaMSFailureExceptionCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'duplicateAddressException': this.upDownduplicateAddressExceptionCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'expectedReimbursementRate': this.upDownexpectedReimbursementRateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'eligibleforTransferDate': this.upDowneligibleforTransferDateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'eligibleforDevice': this.upDowneligibleforDeviceCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'eligibleforRate': this.upDowneligibleforRateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'createdDate': this.upDowncreatedDateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      default: break;
    }
    this.GetMonthlySnapshotandSummaryByPaged();
  }

  cleanCssClass() {
    this.upDownsnapshotPeriodCSS = '';
    this.upDownstudyAreaCodeCSS = '';
    this.upDownenrollmentDateCSS = '';
    this.upDownenrollmentCodeCSS = '';
    this.upDowneTCGeneralCSS = '';
    this.upDownlastNameCSS = '';
    this.upDownfirstNameCSS = '';
    this.upDownmiddleNameCSS = '';
    this.upDownAPIApplicationIdCSS='';
    this.upDownsubscriberIdCSS = '';
    this.upDowntelephoneNumberCSS = '';
    this.upDownserviceTypeCSS = '';
    this.upDownmonthandyearCSS = '';
    this.upDownstreetAddressCSS = '';
    this.upDowncityCSS = '';
    this.upDownstateCSS = '';
    this.upDownzIPCSS = '';
    this.upDownmailingStreetAddressCSS = '';
    this.upDownmailingCityCSS = '';
    this.upDownmailingStateCSS = '';
    this.upDownmailingZIPCSS = '';
    this.upDownserviceInitiationDateCSS = '';
    this.upDowneligibilityProgramCSS = '';
    this.upDownbQPLastNameCSS = '';
    this.upDownbQPFirstNameCSS = '';
    this.upDownbQPMiddleNameCSS = '';
    this.upDowntribalBenefitFlagCSS = '';
    this.upDowndeviceReimbursementDateCSS = '';
    this.upDowndeviceTypeCSS = '';
    this.upDowndeviceMakeCSS = '';
    this.upDownexpectedDeviceReimbursementRateCSS = '';
    this.upDowndeviceCopayCSS = '';
    this.upDowndeviceDeliveryMethodCSS = '';
    this.upDowndeviceModelCSS = '';
    this.upDownmodelNumberCSS = '';
    this.upDownmarketValueCSS = '';
    this.upDownconsumerFeeCSS = '';
    this.upDowncontactPhoneNumberCSS = '';
    this.upDownaVPProgramExceptionCSS = '';
    this.upDownschoolLunchExceptionCSS = '';
    this.upDownaMSFailureExceptionCSS = '';
    this.upDownduplicateAddressExceptionCSS = '';
    this.upDownexpectedReimbursementRateCSS = '';
    this.upDowneligibleforTransferDateCSS = '';
    this.upDowneligibleforDeviceCSS = '';
    this.upDowneligibleforRateCSS = '';
    this.upDowncreatedDateCSS = '';
  }

  ExportDetails() {
    this.isloading = true;
    this.listOfExports = [];
    this.userService.GetSnapshotandSummaryByPaged(this.exportFilterModel).subscribe(
      data => {
        this.listOfExports = data;
        setTimeout(this.Export, 3000);
        this.isloading = false;
      },
      error => {
        console.log(error);
      }
    );
  }

  Export() {
    $('#idexportToExcel')[0].click();
  }

  exportToExcel() {
    let tableData = document.getElementById("ExportTable").innerHTML;

    var uri = 'data:application/vnd.ms-excel;base64,'
      , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body>{table}</body></html>'
      , base64 = function (s) { return window.btoa(unescape(encodeURIComponent(s))) }
      , format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) }
    let html = "<table><tr class='mainhead'> <td colspan='3'>";
    html += tableData;
    html += "</td></tr></table>";
    let name = 'SNAPSHOTsummaryanddetailreport';
    var ctx = { worksheet: name || 'Worksheet', table: html }
    FileSaver.saveAs((uri + base64(format(template, ctx))), "SNAPSHOTsummaryanddetailreport.xls");
  }

  BacktoList() {
    sessionStorage.setItem('snapshotPeriod', '');
    sessionStorage.setItem('monthandyear', '');
    this.router.navigateByUrl(`admin/snapshot-summary-report`);
  }

  pageChanged($event) {
    this.filterModel.pageNumber = $event.page;
    this.GetMonthlySnapshotandSummaryByPaged();
  }
}