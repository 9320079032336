import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Globle } from 'src/app/Shared/global';
import { BillingFilterModel } from 'src/Models/billingFilterModel';
import { ApplicantsModel } from 'src/Models/applicantsModel';
import { UsersService } from 'src/Services/users.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import * as FileSaver from 'file-saver';
import { UniversalService } from 'src/Services/universal.service';
import { Inject } from '@angular/core';
import { filterParameter } from 'src/Models/filterParameter';
import { ApplicationStatusModel } from 'src/Models/ApplicationStatusModel';

@Component({
  selector: 'app-applicants',
  templateUrl: './applicants.component.html',
  styleUrls: ['./applicants.component.css']
})
export class ApplicantsComponent implements OnInit {
  filterModel = new BillingFilterModel();
  exportFilterModel = new BillingFilterModel();
  listOfApplicants: ApplicantsModel[];
  listOfExportApplicants: ApplicantsModel[];
  providerId: string = '0';
  totalRecordsCount: number = 0;
  isAscending: boolean;
  isloading = false;
  recordsCount: number = -1;
  currentPage: number = 1;
  isViewButtonShow: boolean = false;

  upDownAccountNumberCSS: string = '';
  upDownNameCSS: string = '';
  upDownLocationNameCSS: string = '';
  upDownEligiblityStatusCSS: string = '';
  upDownEligibilityExpirationDateCSS: string = '';
  upDownEliglibityLastStatusUpdateDateCSS: string = '';
  upDownIsEnrollerdCSS: string = '';
  upDownEnrollStatusCSS: string = '';
  upDownEnrollmentDateCSS: string = '';
  upDownEmailCSS: string = '';
  upDownApplicationNoCSS: string = '';
  upDownIsCancelledCSS: string = '';
  upDownIsActiveCSS: string = '';
  upDownDeEnrolledCSS: string = '';
  upDownCancelledDateCSS: string = '';
  upDownDeEnrollDateCSS: string = '';
  upDownCreateUpdateByCSS: string = '';
  upDownProgramNameCSS: string = '';
  upDownSubscriberIdCSS: string = '';
  // Is Enrolled
  dropdownListIsEnrolled = [];
  selectedItemsIsEnrolled = [];
  dropdownSettingsIsEnrolled = {};
  listOfIsEnrolled: string[] = [];

  dropdownListIsCancelled = [];
  selectedItemsIsCancelled = [];
  dropdownSettingsIsCancelled = {};
  listOfIsCancelled: string[] = [];
  applicationStatusModel = new ApplicationStatusModel()

  deEnrollId: number = 0;
  @ViewChild('processDeEnrollConfirmation') opneDeEnrollModel: ElementRef;
  @ViewChild('btnCancel') btnCancel: ElementRef;
  deEnrollApplicationForm: FormGroup;
  startMaxDate;
  bsDateValue;
  startMinDate;
  isDocloading: boolean = false;
  deleteApplicationHistoryId: number = 0;
  @ViewChild('closeDeleteConfirmation') closeDeleteConfirmation: ElementRef;

  isDeleteButtonShow: boolean = false;
  isTransferButtonShow: boolean = false;

  deEnrollApplicationModel: any;
  @ViewChild('manuallyDeEnrollButton') manuallyDeEnrollButton: ElementRef;
  DeEnrollErrorDesc: any = '';
  DeEnrollerrorType: any = '';

  filterParameter = new filterParameter();

  constructor(
    @Inject(Window) private win: Window, private universalService: UniversalService,
    private _fb: FormBuilder, private router: Router, private userService: UsersService, private titleService: Title,
    private globle: Globle, private toastr: ToastrService) {
    this.providerId = this.globle.cUser.providerId.toString();
    this.filterModel.providerIds = this.providerId;
    this.filterModel.pageNumber = 1;
    this.filterModel.pageSize = 10;
    this.filterModel.orderBy = 'Id desc';
    this.titleService.setTitle('Applicants');
    this.deEnrollApplicationForm = this._fb.group({
      deEnrollId: [this.deEnrollId],
      deEnrollDate: [''],
      transactionType: [''],
    });
    this.startMinDate = new Date();
    this.startMaxDate = new Date();

    if (this.globle.cUser.userGroupId == 2 || this.globle.cUser.userGroupId == 5) 
    {
      this.isDeleteButtonShow = false;
      this.isTransferButtonShow = false;
      this.isViewButtonShow = false;
    }
    else{
      this.isDeleteButtonShow = true;
      this.isTransferButtonShow = true;
      this.isViewButtonShow = true;
    }

  }

  ngOnInit() {
    this.GetApplicantsByPaged();
    this.GetIsEnrolledData();
    this.GetIsCancelledData();
  }

  GetApplicantsByPaged() {
    this.isloading = true;
    this.listOfApplicants = [];
    this.userService.GetApplicantsByPaged(this.filterModel).subscribe(
      data => {
        this.exportFilterModel = Object.assign({}, this.filterModel);
        this.exportFilterModel.pageSize = 100000;
        this.exportFilterModel.pageNumber = 1;
        this.isloading = false;
        this.listOfApplicants = data;
        if (this.listOfApplicants != null) {
          if (this.listOfApplicants.length > 0) {
            this.totalRecordsCount = this.listOfApplicants[0].totalRecordCount;
            this.recordsCount = this.totalRecordsCount;
          }
          else {
            this.totalRecordsCount = 0;
            this.recordsCount = 0;
          }
        }
        else {
          this.totalRecordsCount = 0;
          this.recordsCount = 0;
        }
      },
      error => {
        this.isloading = false;
        this.recordsCount = 0;
        console.log(error);
      }
    );
  }

  sort(sortBy: any) {
    this.cleanCssClass();
    this.isAscending = !this.isAscending;
    this.filterModel.orderBy = this.isAscending ? sortBy : sortBy + ' DESC';

    switch (sortBy) {
      case 'accountNumber': this.upDownAccountNumberCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'name': this.upDownNameCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'locationName': this.upDownLocationNameCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'eligiblityStatus': this.upDownEligiblityStatusCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'eligibilityExpirationDate': this.upDownEligibilityExpirationDateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'eliglibityLastStatusUpdateDate': this.upDownEliglibityLastStatusUpdateDateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'isSuccessEnrolled': this.upDownIsEnrollerdCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'enrollStatus': this.upDownEnrollStatusCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'enrollmentDate': this.upDownEnrollmentDateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'emailId': this.upDownEmailCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'apiApplicationId': this.upDownApplicationNoCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'isCancelled': this.upDownIsCancelledCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'isActive': this.upDownIsActiveCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'deEnrolled': this.upDownDeEnrolledCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'cancelledDate': this.upDownCancelledDateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'deEnrollDate': this.upDownDeEnrollDateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'createUpdateByName': this.upDownCreateUpdateByCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'programName': this.upDownProgramNameCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'subscriberId': this.upDownSubscriberIdCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      default: break;
    }
    this.GetApplicantsByPaged();
  }

  cleanCssClass() {
    this.upDownAccountNumberCSS = '';
    this.upDownNameCSS = '';
    this.upDownLocationNameCSS = '';
    this.upDownEligiblityStatusCSS = '';
    this.upDownEligibilityExpirationDateCSS = '';
    this.upDownEliglibityLastStatusUpdateDateCSS = '';
    this.upDownIsEnrollerdCSS = '';
    this.upDownEnrollStatusCSS = '';
    this.upDownEnrollmentDateCSS = '';
    this.upDownEmailCSS = '';
    this.upDownApplicationNoCSS = '';
    this.upDownIsCancelledCSS = '';
    this.upDownIsActiveCSS = '';
    this.upDownDeEnrolledCSS = '';
    this.upDownCancelledDateCSS = '';
    this.upDownDeEnrollDateCSS = '';
    this.upDownCreateUpdateByCSS = '';
    this.upDownCreateUpdateByCSS = '';
    this.upDownProgramNameCSS = '';
    this.upDownSubscriberIdCSS = ''; 
  }

  pageChanged($event) {
    this.filterModel.pageNumber = $event.page;
    this.GetApplicantsByPaged();
  }

  keywordFilters($event) {
    this.isloading = true;
    this.filterModel.whereCondition = $event.target.value;
    this.filterModel.pageNumber = 1;
    this.currentPage = 1;
    this.GetApplicantsByPaged();
  }

  editSubscriberApplication(id, appId) {
    if (sessionStorage.getItem('isOnlineApplicationHistoryId') != null) {
      sessionStorage.removeItem('isOnlineApplicationHistoryId');
    }
    sessionStorage.setItem('isFrontEligibliityComplated', 'false')
    this.router.navigate(['admin/applicants/edit/' + id + '/' + appId]);
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  onItemSelectIsEnrolled($event) {
    this.listOfIsEnrolled = [];
    if (this.listOfIsEnrolled != null) {
      if (this.listOfIsEnrolled.length > 0) {
        this.listOfIsEnrolled = this.removeElemetninArray(this.listOfIsEnrolled, $event.id)
        this.listOfIsEnrolled.push($event.id);
      }
      else {
        this.listOfIsEnrolled.push($event.id);
      }
    }

    this.filterModel.isSuccessEnrolled = this.listOfIsEnrolled.join(',');
    this.filterModel.pageNumber = 1;
    this.currentPage = 1;
    if ($event.id == -1)
      this.filterModel.isSuccessEnrolled = null;
    this.GetApplicantsByPaged();
  }

  OnItemDeSelectIsEnrolled($event) {
    if (this.listOfIsEnrolled != null) {
      if (this.listOfIsEnrolled.length > 0) {
        this.listOfIsEnrolled = this.removeElemetninArray(this.listOfIsEnrolled, $event.id)
      }
    }
    this.filterModel.isSuccessEnrolled = this.listOfIsEnrolled.join(',');
    this.filterModel.pageNumber = 1;
    this.currentPage = 1;
    this.GetApplicantsByPaged();
  }
  onSelectAllIsEnrolled($event) {
    this.listOfIsEnrolled = [];
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfIsEnrolled != null) {
        if (this.listOfIsEnrolled.length > 0) {
          this.listOfIsEnrolled = this.removeElemetninArray(this.listOfIsEnrolled, $event[iCounter].id)
          this.listOfIsEnrolled.push($event[iCounter].id);
        }
        else {
          this.listOfIsEnrolled.push($event[iCounter].id);
        }
      }
    }
    this.filterModel.isSuccessEnrolled = this.listOfIsEnrolled.join(',');
    this.filterModel.pageNumber = 1;
    this.currentPage = 1;
    this.GetApplicantsByPaged();
  }

  onDeSelectAllIsEnrolled($event) {
    this.listOfIsEnrolled = [];
    this.filterModel.isSuccessEnrolled = '';
    this.filterModel.pageNumber = 1;
    this.currentPage = 1;
    this.GetApplicantsByPaged();
  }

  GetIsEnrolledData(): void {

    this.dropdownSettingsIsEnrolled = {
      singleSelection: true,
      text: 'Filter by Enrolled',
      enableSearchFilter: false,
      classes: 'borderLessTextBoxGreen',
      badgeShowLimit: 1,
      noDataLabel: 'No enrolled available',
      showCheckbox: false,
      enableFilterSelectAll: false,
      enableCheckAll: false
    };

    this.dropdownListIsEnrolled.push({ 'id': -1, 'itemName': 'All' });
    this.dropdownListIsEnrolled.push({ 'id': 1, 'itemName': 'Yes' });
    this.dropdownListIsEnrolled.push({ 'id': 0, 'itemName': 'No' });

  }

  GetIsCancelledData(): void {

    this.dropdownSettingsIsCancelled = {
      singleSelection: true,
      text: 'Filter by Cancelled',
      enableSearchFilter: false,
      classes: 'borderLessTextBoxGreen',
      badgeShowLimit: 1,
      noDataLabel: 'No cancelled available',
      showCheckbox: false,
      enableFilterSelectAll: false,
      enableCheckAll: false
    };

    this.dropdownListIsCancelled.push({ 'id': -1, 'itemName': 'All' });
    this.dropdownListIsCancelled.push({ 'id': 1, 'itemName': 'Yes' });
    this.dropdownListIsCancelled.push({ 'id': 0, 'itemName': 'No' });

  }

  onItemSelectIsCancelled($event) {
    this.listOfIsCancelled = [];
    if (this.listOfIsCancelled != null) {
      if (this.listOfIsCancelled.length > 0) {
        this.listOfIsCancelled = this.removeElemetninArray(this.listOfIsCancelled, $event.id)
        this.listOfIsCancelled.push($event.id);
      }
      else {
        this.listOfIsCancelled.push($event.id);
      }
    }

    this.filterModel.isCancelled = this.listOfIsCancelled.join(',');
    this.filterModel.pageNumber = 1;
    this.currentPage = 1;
    if ($event.id == -1)
      this.filterModel.isCancelled = null;
    this.GetApplicantsByPaged();
  }

  OnItemDeSelectIsCancelled($event) {
    if (this.listOfIsCancelled != null) {
      if (this.listOfIsCancelled.length > 0) {
        this.listOfIsCancelled = this.removeElemetninArray(this.listOfIsCancelled, $event.id)
      }
    }
    this.filterModel.isCancelled = this.listOfIsCancelled.join(',');
    this.filterModel.pageNumber = 1;
    this.currentPage = 1;
    this.GetApplicantsByPaged();
  }
  onSelectAllIsCancelled($event) {
    this.listOfIsCancelled = [];
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfIsCancelled != null) {
        if (this.listOfIsCancelled.length > 0) {
          this.listOfIsCancelled = this.removeElemetninArray(this.listOfIsCancelled, $event[iCounter].id)
          this.listOfIsCancelled.push($event[iCounter].id);
        }
        else {
          this.listOfIsCancelled.push($event[iCounter].id);
        }
      }
    }
    this.filterModel.isCancelled = this.listOfIsCancelled.join(',');
    this.filterModel.pageNumber = 1;
    this.currentPage = 1;
    this.GetApplicantsByPaged();
  }

  onDeSelectAllIsCancelled($event) {
    this.listOfIsCancelled = [];
    this.filterModel.isCancelled = '';
    this.filterModel.pageNumber = 1;
    this.currentPage = 1;
    this.GetApplicantsByPaged();
  }

  setDeEnrollSubscriberId(id) {
    this.deEnrollId = id;
    let obj = this.listOfApplicants.filter(m => m.id == id);
    if (obj != null) {
      const d = new Date(obj[0].enrollmentDate);
      this.startMinDate = new Date(d.setDate(d.getDate()));
      let curDate = new Date();
      this.startMaxDate = new Date(curDate.setDate(curDate.getDate() - 1));
      //d.getFullYear() + '-' + this.pad(d.getMonth() + 1) + '-' + this.pad(d.getDate());
    }
  }

  dateFormatString(date) {
    const d = new Date(date);
  }

  onValueChange(value: Date) {
    const d = new Date(value);
    this.bsDateValue = [this.pad(d.getMonth() + 1), this.pad(d.getDate()), d.getFullYear()].join('/');
    return [this.pad(d.getMonth() + 1), this.pad(d.getDate()), d.getFullYear()].join('/');
  }

  pad(s) {
    return (s < 10) ? '0' + s : s;
  }

  DeEnrollApplication(deEnrollApplicationForm) {
    if (deEnrollApplicationForm['controls'].deEnrollDate.value == '' || deEnrollApplicationForm['controls'].deEnrollDate.value == null || typeof (deEnrollApplicationForm['controls'].deEnrollDate.value) == 'undefined') {
      this.toastr.error('Please select de-enroll date', 'Error');
      return;
    }

    if (deEnrollApplicationForm['controls'].transactionType.value == '' || deEnrollApplicationForm['controls'].transactionType.value == null || typeof (deEnrollApplicationForm['controls'].transactionType.value) == 'undefined') {
      this.toastr.error('Please select Reason for the De-Enrollment', 'Error');
      return;
    }

    deEnrollApplicationForm.value["deEnrollDate"] = this.bsDateValue;

    deEnrollApplicationForm.value["CreatedBy"] =  this.globle.cUser.id;
    
    this.deEnrollApplicationModel = deEnrollApplicationForm.value;

    this.userService.DeEnrollmentSubscriber(deEnrollApplicationForm.value).subscribe(
      data => {
        
        this.DeEnrollErrorDesc = '';
        this.DeEnrollerrorType = '';

        this.btnCancel.nativeElement.click();
        if (data['status'] == 'Success') {
          this.GetApplicantsByPaged();
          this.toastr.success('De-enroll successfully', 'De-enroll');
        }
        else if (data["error_Type"] != null && data["error_Type"] != '') {
          // this.toastr.error(data['error_Description'], 'De-enroll ' + data["error_Type"]);
          // if (data['error_Description'].toString().indexOf('SUBSCRIBER_NOT_FOUND') > -1) {
          //   this.manuallyDeEnrollButton.nativeElement.click();
          // }
          if (data['error_Description'].toString() != null) {
            this.DeEnrollerrorType = data["error_Type"].toString();
            this.DeEnrollErrorDesc = data['error_Description'].toString();
            this.manuallyDeEnrollButton.nativeElement.click();
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  OpenDeEnrollModel() {
    this.opneDeEnrollModel.nativeElement.click();
    this.deEnrollApplicationForm.setValue({
      deEnrollId: this.deEnrollId,
      deEnrollDate: null,
      transactionType: ''
    });
  }

  exportToExcel() {
    let tableData = document.getElementById("ExportTable").innerHTML;

    var uri = 'data:application/vnd.ms-excel;base64,'
      , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body>{table}</body></html>'
      , base64 = function (s) { return window.btoa(unescape(encodeURIComponent(s))) }
      , format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) }
    // let html = "<table><tr class='mainhead'> <td colspan='3'>Location:" + ((this.selectedLocation != undefined && this.selectedLocation.length > 0) ? this.selectedLocation[0].name : 'All') + "</td>";
    // html += "</tr><tr class='mainhead'>    <td colspan='3'> From Year: " + this.filter.FromYear + " To: " + (this.filter.ToYear != undefined ? this.filter.ToYear : '') + "</td>  </tr>";
    let html = "<table><tr class='mainhead'> <td colspan='3'>";
    html += tableData;
    html += "</td></tr></table>";
    // html += "<tr><td style='font-weight: bold;'>Total</td><td>" + this.analyticModel.new_Approve_Count + "</td><td>" + this.analyticModel.reNew_Reject_Count + "</td></tr>"
    // html += "</table>";
    let name = 'ACPApplicants';
    var ctx = { worksheet: name || 'Worksheet', table: html }
    FileSaver.saveAs((uri + base64(format(template, ctx))), "ACPApplicants.xls");
  }

  GetExportApplicantsByPaged() {
    this.listOfExportApplicants = [];

    this.userService.GetApplicantsByPagedExport(this.exportFilterModel).subscribe(
      data => {
        if (data != null) {
          this.isDocloading = true;
          if (data.status == 'Success') {
            this.filterParameter.path = data.responseNewFilename;
            this.userService.DownloadDocument(this.filterParameter).subscribe(
              fData => {
                this.isDocloading = false;
                FileSaver.saveAs(fData, "Applicants.csv");
              },
              error => {
                console.log(error);
              }
            );
          }
          else {
            this.isDocloading = false;
            this.toastr.error(data.status, 'Error');
          }

        }
      },
      error => {
        this.isDocloading = false;
        console.log(error);
      }
    );
  }

  redirectToAddNew() {
    if (sessionStorage.getItem('isOnlineApplicationHistoryId') != null) {
      sessionStorage.removeItem('isOnlineApplicationHistoryId');
    }
    sessionStorage.setItem('isFrontEligibliityComplated', 'false')
    if (sessionStorage.getItem('applicationHistoryId') != null)
      sessionStorage.removeItem('applicationHistoryId');
    this.router.navigate(['admin/applicants/addnew']);
  }

  redirectToAddNewTrasferIN() {
    if (sessionStorage.getItem('isOnlineApplicationHistoryId') != null) {
      sessionStorage.removeItem('isOnlineApplicationHistoryId');
    }
    sessionStorage.setItem('isFrontEligibliityComplated', 'false')
    if (sessionStorage.getItem('applicationHistoryId') != null)
      sessionStorage.removeItem('applicationHistoryId');
    this.router.navigate(['admin/applicants/add-transfer-in']);
  }

  setColor(isSuccessEnrolled: any, enrollStatus: any, eligiblityStatus: any) {
    if ((enrollStatus != '' && enrollStatus != null)) {
      if (isSuccessEnrolled == '1' && enrollStatus == 'Subscriber successfully enrolled') {
        return '';
      }
      else {
        return 'clsErrorEnrollColor';
      }
    }
    else if (eligiblityStatus != 'COMPLETE') {
      return 'clsEligliblityErrorEnrollColor';
    }
    else
      return '';
  }

  certifyApplication(eligibilityId: any, isRedirect: boolean) {
    this.isDocloading = true;
    this.universalService.CheckEligibilityStatus(eligibilityId, true).subscribe(
      data => {
        this.isDocloading = false;
        if (data != null) {
          if (data['_links'] != null) {
            if (data['_links']["resolution"] != null) {
              if (isRedirect) {
                let URL = data['_links']["resolution"]["href"];
                this.win.open(URL);
              }
            }
          }
        }
      },
      error => {
        this.isDocloading = false;
        console.log(error);
      }
    );
  }

  resubmitApplication(applicationHistoryId: any, isNVAPICall: any, onlineRegistrationId: any) {
    if (isNVAPICall) {
      sessionStorage.setItem('isFrontEligibliityComplated', 'true')
      sessionStorage.setItem('isOnlineApplicationHistoryId', applicationHistoryId)
      this.router.navigate(['admin/applicants/addnew/' + onlineRegistrationId]);
    }
    else {
      sessionStorage.setItem('applicationHistoryId', applicationHistoryId);
      sessionStorage.setItem('isFrontEligibliityComplated', 'false')
      this.router.navigate(['/admin/applicants/addnew']);
    }
  }

  setDeleteApplicationId(deleteApplicationHistoryId) {
    this.deleteApplicationHistoryId = deleteApplicationHistoryId
  }

  deleteApplication(deleteApplicationHistoryId) {
    if (deleteApplicationHistoryId > 0) {
      this.userService.DeletApplicationFindByApplicationId(deleteApplicationHistoryId).subscribe(
        data => {
          if (data != null) {
            this.clearDeleteApplicationHistoryId();
            if (data > 0) {
              this.toastr.success('Application deleted successfully', 'Delete');
              this.closeDeleteConfirmation.nativeElement.click();
              this.GetApplicantsByPaged();
            }
          }
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  clearDeleteApplicationHistoryId() {
    this.deleteApplicationHistoryId = 0;
  }

  manuallyDeEnrollmentSubscriber() {
    this.userService.manuallyDeEnrollmentSubscriber(this.deEnrollApplicationModel).subscribe(
      data => {
        if (data.status == 'Success') {
          this.GetApplicantsByPaged();
          this.toastr.success('Manually De-enroll successfully', 'Manually De-enroll');
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  transferSubscriberApplication(id, appId) {
    if (sessionStorage.getItem('isOnlineApplicationHistoryId') != null) {
      sessionStorage.removeItem('isOnlineApplicationHistoryId');
    }
    sessionStorage.setItem('isFrontEligibliityComplated', 'false')
    this.router.navigate(['admin/applicants/transfer/' + id + '/' + appId]);
  }
  
  ViewDetails(applicationHistoryId) {
    sessionStorage.setItem('viewId', applicationHistoryId);
    this.router.navigate(['/admin/applicants/view']);
  }

}
